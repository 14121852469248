<template>
    <div>
        <div class="tw-flex tw-space-x-2">
            <k-button type="button" @click="$store.dispatch('AUTH/signInWithProvider', 'google.com')" color="secondary" class="tw-flex-1" :disabled="disableBtn">
                <img src="./icons/google.svg" alt="Google" title="Google" />
                <span class="google-text">
                    <span class="ml-4 tw-font-bold" v-if="signUp">{{ $t('auth.actions.create_account') }}</span>
                    <span class="ml-4 tw-font-bold" v-else>{{ $t('auth.actions.sign_in') }}</span>
                </span>
            </k-button>
            <!-- <k-button type="button" @click="$store.dispatch('AUTH/SIGN_IN_WITH_PROVIDER', 'facebook.com')" color="secondary">
                <img src="./icons/facebook.svg" alt="Facebook" title="Facebook" />
            </k-button> -->
            <k-button type="button" @click="$store.dispatch('AUTH/signInWithProvider', 'github.com')" color="secondary" :disabled="disableBtn">
                <img src="./icons/github.svg" alt="Github" title="Github" />
            </k-button>
        </div>
        <div class="alternatives-title">
            <div class="alternatives-title__line-container">
                <div class="alternatives-title__line"></div>
            </div>
            <div class="alternatives-title__text-container">
                <span class="alternatives-title__text">{{ $t('global.or') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        signUp: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        disableBtn() {
            const signInStatus = this.$store.getters['AUTH/getStatus']('signInStatus')
            const signUpStatus = this.$store.getters['AUTH/getStatus']('signUpStatus')

            return signInStatus.isLoading || signUpStatus.isLoading
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.alternatives-title {
    position: relative;
    margin-top: 2rem;
}
.alternatives-title__line-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.alternatives-title__line {
    width: 100%;
    border-top-style: solid;
    border-top-width: 0.063rem;
    border-color: $koderia-border-grey;
}

.alternatives-title__text-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.alternatives-title__text {
    background-color: white;
    padding: 0 0.75rem;
    color: $koderia-grey;
    font-size: 0.875rem;
}

.alternatives-grid {
    display: grid;
    grid-template-columns: 1fr 3rem 3rem;
    gap: 0.5rem;

    @include -screen(380) {
        grid-template-columns: 1fr 1fr 1fr;
        .google-text {
            display: none;
        }
    }
}

.button {
    font-weight: bold;
    border-radius: 0.125rem;
    border: 0.063rem solid;
    color: $koderia-grey;
    border-color: $koderia-light-grey;
    background-color: #fafbfd;
    height: 3rem;
}

::v-deep .v-btn__content {
    font-size: 0.875rem;
    text-transform: none;
    letter-spacing: 0;
    color: $koderia-grey;
}

.button--alternatives {
    border: 0.063rem solid $koderia-border-grey;
}

.button--square {
    width: 3rem;
    height: 3rem;
}
</style>
